/*---- External -------------------------------------------------------------*/

import React from 'react'
import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

interface BannerProps {
  borderRadius?: number
  borderWidth?: number
  outerClassName?: string
  innerClassName?: string
}

export const Banner: React.FC<BannerProps> = ({
  children,
  borderRadius = 8,
  borderWidth = 1,
  outerClassName = '',
  innerClassName = '',
}) => {
  return (
    <div
      className={classNames(
        'bg-gradient-to-br from-blue-600 to-teal-400',
        outerClassName
      )}
      style={{ borderRadius, padding: `${borderWidth}px` }}
    >
      <div
        className={classNames('h-full bg-white', innerClassName)}
        style={{ borderRadius: borderRadius - borderWidth }}
      >
        {children}
      </div>
    </div>
  )
}
