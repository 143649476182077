/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { useStaticQuery, graphql } from 'gatsby'
import { PricesYaml, UsePricesQuery } from '../../graphql-types'

/*---------------------------------------------------------------------------*/

type Price = Pick<
  PricesYaml,
  | 'id'
  | 'name'
  | 'title'
  | 'highlight'
  | 'action'
  | 'description'
  | 'includes'
  | 'excludes'
>

export const usePrices = (): Price[] => {
  const { allPricesYaml } = useStaticQuery<UsePricesQuery>(
    graphql`
      query UsePrices {
        allPricesYaml {
          nodes {
            id
            name
            title
            highlight
            action
            description
            includes
            excludes
          }
        }
      }
    `
  )
  return allPricesYaml.nodes
}
