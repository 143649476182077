/*---- External -------------------------------------------------------------*/

import React from 'react'

import { Helmet } from 'react-helmet'

/*---- Qualdesk -------------------------------------------------------------*/

import { useSiteMetadata } from '../hooks/useSiteMetadata'

/*---------------------------------------------------------------------------*/

interface SEOMetaProps {
  description?: string
  title?: string
  image?: string
  path: string
}

export const SEOMeta: React.FC<SEOMetaProps> = ({
  description,
  title,
  image,
  path,
}) => {
  const defaultMetadata = useSiteMetadata()
  const resolvedTitle = title ? title : defaultMetadata.title
  const metaDescription = description || defaultMetadata.description
  const resolvedImage = `${defaultMetadata.siteUrl}${
    image || defaultMetadata.image
  }`
  const url = `${defaultMetadata.siteUrl}${path || '/'}`

  return (
    <Helmet
      title={resolvedTitle}
      titleTemplate={`%s — ${defaultMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: resolvedTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: resolvedImage,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: `og:site_name`,
          content: 'Qualdesk',
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: defaultMetadata.author,
        },
        {
          name: `twitter:title`,
          content: resolvedTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: resolvedImage,
        },
      ]}
    />
  )
}
