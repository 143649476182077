import React, { useState } from 'react'
import { Link } from 'gatsby'
import {
  ArrowUpRight,
  CaretDown,
  CaretUp,
  ChatCentered,
  Check,
  X,
} from 'phosphor-react'
import { usePrices } from '../hooks/usePrices'
import { BasicLayout } from '../layouts/basic'
import { SEOMeta } from '../components/SEOMeta'
import { Banner } from '../components/Banner'
import classNames from 'classnames'

// TODO: tsx
const Pricing = ({ location }) => {
  const prices = usePrices()

  return (
    <BasicLayout>
      <SEOMeta title="Pricing" path={location.pathname} />
      <div className="mx-auto p-6 pb-12 max-w-6xl">
        <header className="mb-12 sm:text-center lg:mb-20 lg:mt-8">
          <h1 className="mx-auto max-w-sm text-text-secondary-light text-lg font-medium tracking-tight leading-tight">
            Pricing
          </h1>
          <h2 className="mt-1 mx-auto max-w-sm text-text-primary-light text-2xl font-extrabold tracking-tight leading-tight sm:max-w-md sm:text-3xl md:max-w-lg md:text-4xl lg:max-w-2xl lg:text-5xl">
            One tool for your whole team and all of your work
          </h2>
        </header>
        <section className="mx-auto max-w-max space-y-12 lg:flex lg:space-x-6 lg:space-y-0">
          {prices.map((price) =>
            price.highlight ? (
              <Banner
                key={price.id}
                borderWidth={4}
                borderRadius={16}
                outerClassName="w-80 shadow-solid"
                innerClassName="py-8 px-6 text-center"
                withShadow
              >
                <Price price={price} />
              </Banner>
            ) : (
              <div className="px-6 py-8 w-80 text-center bg-white border border-border-normal-light rounded-2xl lg:bg-transparent lg:border-4 lg:border-transparent">
                <Price price={price} />
              </div>
            )
          )}
        </section>
      </div>
    </BasicLayout>
  )
}

const Price = ({ price }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="relative">
      <h3 className="text-text-primary-light text-xl tracking-tight leading-tight">
        {price.name}
      </h3>
      <div className="mt-6 text-text-primary-light text-2xl font-extrabold tracking-tight leading-tight">
        {price.title}
      </div>
      <ul class="mt-2 text-text-secondary-light">
        {price.description.map((item) => (
          <li key={item} className="whitespace-pre">
            {item}
          </li>
        ))}
      </ul>
      <div
        className={classNames(
          'py-6 bg-white border-b border-border-weak-light md:top-20 lg:sticky',
          {
            'sticky top-16': isOpen,
            'lg:bg-background-four-light': !price.highlight,
          }
        )}
      >
        {price.action === 'signup' && (
          <a
            className="inline-flex items-center justify-between p-4 h-14 text-white text-sm leading-tight active:bg-button-primary-active-light hover:bg-button-primary-hovered-light bg-button-primary-light border border-transparent rounded-lg space-x-2"
            href={process.env.SIGNUP_URL}
          >
            <span className="-mt-0.5">Use Qualdesk now</span>
            <ArrowUpRight className="relative left-0.5" size={20} />
          </a>
        )}
        {price.action === 'contact' && (
          <Link
            className="inline-flex items-center p-4 pr-3.5 h-14 text-text-primary-light text-sm leading-tight active:bg-button-secondary-active-light hover:bg-white border border-border-normal-light rounded-lg space-x-2"
            to="/contact"
          >
            <span className="-mt-0.5">Contact us</span>
            <ChatCentered
              className="relative left-0.5 flex-shrink-0"
              size={20}
              weight="light"
            />
          </Link>
        )}
      </div>
      <section className="mt-8">
        <button
          className="inline-flex items-center text-text-secondary-light space-x-2 lg:hidden"
          onClick={() => setIsOpen((isOpen) => !isOpen)}
        >
          <span>{isOpen ? 'Hide' : 'Show'} plan details</span>
          {isOpen ? <CaretUp size={16} /> : <CaretDown size={16} />}
        </button>
        <ol
          className={classNames('mt-10 text-left space-y-5', {
            'sr-only lg:not-sr-only': !isOpen,
          })}
        >
          {price.includes.map((include) => (
            <li className="flex items-center text-text-primary-light space-x-3">
              <Check className="text-icon-accent-light" size={20} />
              <span>{include}</span>
            </li>
          ))}
          {price.excludes.map((exclude) => (
            <li className="flex items-center text-text-tertiary-light space-x-3">
              <X size={20} />
              <span>{exclude}</span>
            </li>
          ))}
        </ol>
      </section>
    </div>
  )
}

export default Pricing
